<template>
    <v-card>
      <v-card-title>{{$t('to')}}: cabaretflamencomtl@gmail.com</v-card-title>
      <v-container>
      <!-- <v-divider></v-divider> -->
      <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field outlined :label="$t('from')" placeholder="Ex. jean-coutu@gmail.com" v-model="fromEmail" :rules = emailRules></v-text-field>
          <v-textarea outlined :label="$t('your_msg')" v-model="text"></v-textarea>
          </v-form>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="secondary" class="text-capitalize primary--text" @click="cancel">
              {{$t('cancel')}}
              <v-icon right>cancel</v-icon>
              </v-btn>
          <v-btn v-if="fromEmail" rounded color="secondary" class="text-capitalize primary--text" :disabled="!valid" @click="sendMsg">
              {{$t('send')}}
              <v-icon right>
                  send
              </v-icon>
              </v-btn>
      </v-card-actions>
       </v-container>
    </v-card>
</template>

<script>
import { fstore } from '@/plugins/firebase'
export default {
  data: () => ({
    valid: true,
    fromEmail: '',
    text: ''
  }),
  computed: {
    emailRules () {
      return [
        v => !!v || this.$t('emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('emailValid')
      ]
    }
  },
  methods: {
    cancel () {
      this.$emit('close-dialog')
    },
    sendMsg () {
      this.$refs.form.validate()
      const emailObj = {}
      emailObj.from = this.fromEmail
      emailObj.text = this.text
      //   console.log('Email Object to be written: ', emailObj)
      const contactRef = fstore.collection('contactForm_cabaret').doc()
      const id = contactRef.id
      fstore.collection('contactForm_cabaret').doc(id).set(emailObj).then(() => {
        this.$emit('close-dialog')
      }).catch(err => {
        // this.errMsg = err
        this.$store.dispatch('errMsgAction', err)
        console.log('Error :', err.message)
      })
    }
  }
}
</script>
<i18n>
{
    "en": {
    "email": "E-mail address",
    "emailRequired": "E-mail address is required",
    "emailValid": "E-mail address must be valid ",
    "to":"To",
    "from":"From",
    "your_msg":"Your Message",
    "report_problem":"Report a problem",
    "contact":"Contact",
    "send":"send",
    "cancel":"cancel"
    },
    "fr": {
    "email": "Adresse électronique",
    "emailRequired": "Adresse e-mail est nécessaire",
    "emailValid": "L'adresse e-mail doit être valide",
    "to":"À",
    "from":"De",
    "your_msg":"Votre message",
    "report_problem":"Signaler un problème",
    "contact":"Contact",
    "send":"envoyer",
    "cancel":"annuler"
    },
    "es": {
    "email": "Correo electrónico",
    "emailRequired": "Se requiere dirección de correo electrónico",
    "emailValid": "La dirección de correo electrónico debe ser válida",
    "to":"A",
    "from":"De",
    "your_msg":"Tu mensaje",
    "report_problem":"Informar de un problema",
    "contact":"Contacto",
    "send":"enviar",
    "cancel":"anular"
    }
}
</i18n>
